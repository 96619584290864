import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import { jwtDecode } from 'jwt-decode';

import BASE_URL from '../../config/index';

export const login = createAsyncThunk('auth/login', async (credentials, thunkAPI) => {
  try {
    // Destructure userCode and password from credentials
    let { userCode, password } = credentials;

    // Convert userCode to lowercase and remove whitespace
    userCode = userCode ? userCode.toLowerCase().replace(/\s+/g, '') : '';

    // Check if userCode or password is empty
    if (!userCode || !password) {
      return thunkAPI.rejectWithValue({ message: 'UserCode and password are required' });
    }

    // Check for mobile device using User-Agent
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobileDevice = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile/i.test(userAgent);

    if (isMobileDevice) {
      return thunkAPI.rejectWithValue({ message: 'Login is not allowed on mobile devices' });
    }

    // Update the credentials object with the modified userCode
    const updatedCredentials = { userCode, password };

    // Make the login request with the updated credentials
    const response = await axios.post(`${BASE_URL}/api/login`, updatedCredentials);

    // Ensure the response has data before returning it
    if (response && response.data) {

      // Decode the JWT token
      let decodedToken;
      try {
        decodedToken = jwtDecode(response.data.token);
      } catch (err) {
        return thunkAPI.rejectWithValue({ message: 'Invalid token received' });
      }

      if (['enforcer', 'remitter'].includes(decodedToken.user?.agentRole) || decodedToken.user?.isMotorist) {
        return thunkAPI.rejectWithValue({ message: 'Contact admin, cannot login' });
      } else {
        localStorage.setItem('accessToken', JSON.stringify(response.data.token));
        localStorage.setItem('isLoggedIn', true);
        return response.status;
      }
    } else {
      // If response is missing or malformed
      return thunkAPI.rejectWithValue({ message: 'No data in response' });
    }
  } catch (error) {
    // Handle different error cases
    if (error.response) {
      // Server responded with a status other than 2xx
      return thunkAPI.rejectWithValue(error.response.data);
    } else if (error.request) {
      // Request was made, but no response received (e.g., network error)
      return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
    } else {
      // Something else happened
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const logout = createAsyncThunk('auth/logout', async (id, thunkAPI) => {
  try {
    // Make the login request with the updated credentials
    const response = await axios.post(`${BASE_URL}/api/logout/${id}`);
    // Ensure the response has data before returning it
    if (response && response.data) {
      return response.status;
    }
  } catch (error) {
    // Handle different error cases
    // Handle different error cases
    if (error.response) {
      // Server responded with a status other than 2xx
      return error
    } else if (error.request) {
      // Request was made, but no response received (e.g., network error)
      return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
    }
  }
});

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (email, thunkAPI) => {
  try {
    // Convert email to lowercase
    email = email ? email.toLowerCase().replace(/\s+/g, '') : '';

    // Check if email or password is empty
    if (!email) {
      return thunkAPI.rejectWithValue({ message: 'Email is required' });
    }

    const response = await axios.post(`${BASE_URL}/api/forgot-password`, { email });
    // Ensure the response has data before returning it
    if (response && response.data) {
      return response.status;
    } else {
      // If response is missing or malformed
      return thunkAPI.rejectWithValue({ message: 'No data in response' });
    }
  } catch (error) {
    // Handle different error cases
    if (error.response) {
      // Server responded with a status other than 2xx
      return thunkAPI.rejectWithValue(error.response.data);
    } else if (error.request) {
      // Request was made, but no response received (e.g., network error)
      return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
    } else {
      // Something else happened
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const resetPassword = createAsyncThunk('auth/resetPassword', async (data, thunkAPI) => {
  try {
    let { totken, password, confirmPassword } = data;
    // Check if email or password is empty
    if (!totken && !password && !confirmPassword) {
      return thunkAPI.rejectWithValue({ message: 'All fields are required' });
    }

    const response = await axios.post(`${BASE_URL}/api/password-reset`, data);
    return response.status;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(logout.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(logout.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { clearError } = authSlice.actions;

export default authSlice.reducer;
