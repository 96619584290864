import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { MdDelete, MdPause, MdCheckCircle, MdExitToApp } from 'react-icons/md'; // Importing icons
import { format } from 'date-fns'; // Import date-fns for formatting
import DataTable from '../../components/DataTable';
import { logout } from '../../redux/auth/authSlice';
import { approve, suspend, deleted } from '../../redux/authenticated/verification';
import { Spinal } from '../../assets/icons/extIcons';
import { isRole } from '../../routerGuard/UserRole';
import { useNavigate } from 'react-router-dom';

import FetchUser from '../../routerGuard/FetchUser';

import {
  fetchAgent,
  setCurrentPage,
  setPerPage,
  setSearchQuery,
  toggleSelectItem,
  setSortConfig,
  setActiveQuery,
  setStartDateQuery,
  setEndDateDateQuery,
} from '../../redux/authenticated/registrarSlice';
const Registrar = () => {
  document.title = "e-Ticketing | Registrar";
  const { role } = isRole();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data, sortConfig, searchQuery, currentPage, perPage, totalPages,
    selectedItems, loading, active, startDate, endDate
  } = useSelector(state => state.registrar);

  const addedByUsers = FetchUser({ data, idKey: 'addedBy' });  // Reuse FetchUser component
  const approvedByUsers = FetchUser({ data, idKey: 'approvedBy' });  // Reuse FetchUser component
  const suspendedByUsers = FetchUser({ data, idKey: 'suspendedBy' });  // Reuse FetchUser component
  
  const tableHeaders = [
    { key: 'fullName', label: 'Full Name' },
    { key: 'phone', label: 'Phone' },
    { key: 'email', label: 'Email' },
    { key: 'lastLogin', label: 'Last Login' },
    { key: 'addedBy', label: 'Added By' },
    { key: 'registrationStatus', label: 'Status' },
    { key: 'actions', label: 'Actions' }, // New column for actions
  ];

  // Helper function to format Last Login using date-fns
  const formatLastLogin = (date) => {
    return date ? format(new Date(date), 'PPpp') : 'N/A'; // Date and time format
  };

  // Render buttons for status
  const renderStatusButton = (status, approverId, suspenderId) => {
    let buttonStyle = '';
    let buttonText = '';

    switch (status) {
      case 'approved':
        buttonStyle = 'text-dim-green'; // Green for approved
        buttonText = `Approved by ${approverId || 'N/A'}`;;
        break;
      case 'pending':
        buttonStyle = 'text-yellow'; // Gray for pending
        buttonText = 'Pending';
        break;
      case 'suspended':
        buttonStyle = 'text-red'; // Red for suspended
        buttonText = `Suspended by ${suspenderId || 'N/A'}`;
        break;
      default:
        buttonStyle = 'text-black'; // Default color if no match
        buttonText = 'Unknown';
    }

    return <button className={`py-1 rounded ${buttonStyle}`}> {buttonText} </button>;
  };

  const handleDelete = (_e) => {
    dispatch(deleted(_e)).then(res => {
      if (res.payload !== 200) {
        return false
      } else {
        dispatch(fetchAgent({
          currentPage, perPage, searchQuery, active, startDate,
          endDate
        }));
      }
    }).catch(err => {
      return err
    });
  }

  const handleSuspend = (_e) => {
    dispatch(suspend(_e)).then(res => {
      if (res.payload !== 200) {
        return false
      } else {
        dispatch(fetchAgent({
          currentPage, perPage, searchQuery, active, startDate,
          endDate
        }));
      }
    }).catch(err => {
      return err
    });
  };

  const handleApprove = (_e) => {
    dispatch(approve(_e)).then(res => {
      if (res.payload !== 200) {
        return false
      } else {
        dispatch(fetchAgent({
          currentPage, perPage, searchQuery, active, startDate,
          endDate
        }));
      }
    }).catch(err => {
      return err
    });
  };

  const handleLogout = (_e) => {
    dispatch(logout(_e)).then(res => {
      if (res.payload !== 200) {
        return false
      } else {
        dispatch(fetchAgent({
          currentPage, perPage, searchQuery, active, startDate,
          endDate
        }));
      }
    }).catch(err => {
      return err
    });
  };

  // Action buttons for each row
  const renderActionButtons = (item) => {
    if (!item || !item.id) {
      return null; // Skip rendering if item or item.id is undefined
    }
    return (
      <div className="flex space-x-2">
        {!item.isDeleted && !['admin', 'manager', 'union', 'user'].includes(role) &&
          <button
            title='Delete'
            className={`bg-red text-white px-3 py-1 rounded`}
            onClick={() => handleDelete(item._id)}
          >
            <MdDelete size={20} />
          </button>
        }
        {item.registrationStatus !== 'suspended' &&
          <button
            title='Suspend'
            className={`bg-yellow text-white px-3 py-1 rounded`}
            onClick={() => handleSuspend(item._id)}
          >
            <MdPause size={20} />
          </button>
        }
        {item.registrationStatus !== 'approved' &&
          <button
            title='Approve'
            className={`bg-dim-green text-white px-3 py-1 rounded`}
            onClick={() => handleApprove(item._id)}
          >
            <MdCheckCircle size={20} />
          </button>
        }
        {item.online &&
          <button
            title='Logout'
            className={`bg-blue text-white px-3 py-1 rounded`}
            onClick={() => handleLogout(item._id)}
          >
            {loading ? <div role="status" className='flex justify-center'> <Spinal /> <span className="sr-only">Loading...</span> </div>
              : <MdExitToApp size={20} />}

          </button>
        }
      </div>
    )
  };

  // Filter the data to only include entries with status "pending"
  const filteredData = data?.filter(item => item.userID?.registrationStatus !== 'pending');

  const handleDetail = (_e) => {
    navigate(`${window.location.pathname === '/' ? 'motorists ' : window.location.pathname}/details`, { state: _e });
  }
  
  // Row rendering function
  const renderRow = (item, showAll) => {
    if (!item || !item.id) {
      return null; // Skip rendering if item or item.id is undefined
    }
    return (<>
      <td className="px-4 py-2 underline" onClick={() => handleDetail(showAll)}>{item.fullName}</td>
      <td className="px-4 py-2">{0 + item.phone}</td>
      <td className="px-4 py-2">{item.email.id}</td>
      <td className="px-4 py-2">{formatLastLogin(item.lastLogin)}</td>
      <td className="px-4 py-2">{addedByUsers[item.addedBy] || 'N/A'}</td>
      <td className="px-4 py-2">{renderStatusButton(item.registrationStatus, approvedByUsers[item.approvedBy], suspendedByUsers[item.suspendedBy])}</td>
      <td className="px-4 py-2">{renderActionButtons(item)}</td>
    </>)
  };

  return (
    <div className="admins mt-16">
      <div className='flex justify-between'>
        <h3 className='font-semibold text-3xl'>Registrar</h3>
        <div className='flex space-x-3 items-center'>
          <div className='w-48'>
            <NavLink to='/newregistrar' className={'bg-blue rounded-md p-4 text-white w-full uppercase text-sm font-medium'}>
              View New Registrar
            </NavLink>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <div className="overflow-x-auto">
          <DataTable
            fetchData={fetchAgent}
            setCurrentPage={setCurrentPage}
            setPerPage={setPerPage}
            setSearchQuery={setSearchQuery}
            toggleSelectItem={toggleSelectItem}
            setSortConfig={setSortConfig}
            setActiveQuery={setActiveQuery}
            setStartDateQuery={setStartDateQuery}
            setEndDateDateQuery={setEndDateDateQuery}
            tableHeaders={tableHeaders}
            renderRow={renderRow}
            selectKey={fetchAgent}
            data={filteredData}
            sortConfig={sortConfig}
            searchQuery={searchQuery}
            currentPage={currentPage}
            perPage={perPage}
            totalPages={totalPages}
            selectedItems={selectedItems}
            loading={loading}
            active={active}
            startDate={startDate}
            endDate={endDate}
            showPagination={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Registrar;
