import TabLoader from '../assets/tablepre2.gif';

const Loader = () => {
  return (
    <div className="absolute flex justify-center items-center h-screen top-0 left-0 right-0">
      <img src={TabLoader} alt="loader" className="w-14 rounded-md bg-graey" />
    </div>
  )
}

export default Loader;