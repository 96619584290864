import React from "react";
import { useLocation } from 'react-router-dom';
import { BiUser, BiSolidCheckShield, BiSolidTagAlt } from "react-icons/bi";
import { format, parseISO } from 'date-fns';
import Button from "../../components/Button";

const Details = () => {

  const location = useLocation(); // Access the location object

  const data = location.state;

  const handlePrint = (imageUrl) => {
    const printWindow = window.open('', '_blank'); // Open a new blank window
    printWindow.document.write(`
    <html>
      <head>
        <title>Print Image</title>
        <style>
          /* General styles */
          body, html { margin: 0; padding: 0; }
          img { max-width: 100%; max-height: 100%; }

          /* Custom styles for elements */
          .flex { display: flex; }
          .flex-col { flex-direction: column; }
          .cursor-pointer { cursor: pointer; }
          .items-center { align-items: center; }
          .justify-end { justify-content: flex-end; }
          .justify-center { justify-content: center; }
          .text-center { text-align: center; }
          .font-thin { font-weight: 200; }
          .font-semibold { font-weight: 600; }
          .font-bold { font-weight: bold; }
          .font-size { font-size: 20px; }
          .font-size-big { font-size: 25px; }
          .text-blue { color: blue; }
          .absolute { position: absolute; }
          .bottom { bottom: -45px; }
          .bottom-32 { bottom: 10rem; }
          .relative { position: relative; }
          .w-11 { width: 2.75rem; }
          .w-32 { width: 8rem; }
          .w-40 { width: 160px; }
          .w-48 { width: 12rem; }
          .w-56 { width: 14rem; }
          .w-full { width: 100%; }
          .h-14 { height: 3.5rem; }
          .mx-2 { margin-left: 0.5rem; margin-right: 0.5rem; }
          .my-2 { margin-top: 0.5rem; margin-bottom: 0.5rem; }

          /* Explicit rotation for printing compatibility */
          .rotate-90 {
            transform: rotate(90deg);
          }
          .-rotate-90 {
            transform: rotate(-90deg);
          }

          /* Explicit left and right positioning */
          .-left-2 {
            left: -0.5rem;
          }
          .-right-4 {
            right: -2rem;
          }
          .right-24 {
            right: 6rem;
          }
        </style>
      </head>
      <body onload="window.print(); window.close();">
        <div class="flex flex-col justify-center cursor-pointer items-center">
          <div class="flex justify-end items-center relative">
            <div class="font-bold font-size -rotate-90 absolute right-24 bottom-32 w-full">EBS / ${data.userID?.userCode}</div>
            <img src="${imageUrl}" alt="Image to print" class="w-56"/>
          </div>
        </div>
      </body>
    </html>
  `);
    printWindow.document.close(); // Close the document to trigger rendering
  };

  const formatDate = (dateString) => {
    try {
      return format(parseISO(dateString), 'MM/d/yyyy');
    } catch (error) {
      return 'N/A'; // fallback if the date is invalid
    }
  };

  return (
    <div className="min-h-screen py-16">
      {/* User Info */}
      <div className="bg-white p-4 rounded-lg w-full">
        {(data.userID || data.role) &&
          <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10">
            <div className="bg-blue p-4 rounded-lg xl:col-span-1 mx-auto">
              <div className="bg-gray-400 h-72 w-72 flex justify-center items-center rounded text-gray-300 mx-auto">
                {data.portraitSelfie ? (
                  <img src={data.portraitSelfie?.url} alt="profile pix" width={'100%'} height={'100%'} className="w-full h-full" />
                ) : (
                  <BiUser fontSize={25} />
                )}
              </div>
            </div>

            <div className="bg-white py-4 rounded-lg xl:col-span-2">
              <div className="flex justify-between">
                <div className={`text-sm font-semibold capitalize flex items-center space-x-2 ${data.registrationStatus === 'approved' ? 'text-green-500' : data.userID.registrationStatus === 'approved' ? 'text-green-500' : 'text-red'}`}>
                  {(data.registrationStatus === 'approved' || data.userID.registrationStatus === 'approved') && <BiSolidCheckShield size={20} />}
                  {data.registrationStatus ?? data.userID.registrationStatus} -
                  <div className={`border border-gray-300 ${data.online ? 'bg-green-500' : data.userID?.online ? 'bg-green-500' : 'bg-red'} rounded-full w-3 h-3`}></div>
                </div>
                <p className="font-semibold my-3 border border-gray-300 border-spacing-4 text-gray-100 bg-orange-500 w-fit rounded-full py-2 px-5 text-sm">UNIQUE ID: {data?.userID ? data.userID?.userCode : data?.userCode}</p>
              </div>

              <div className="grid xl:grid-cols-2 items-center">
                <div className="space-y-2">
                  <h2 className="text-2xl font-semibold capitalize flex items-center space-x-2">
                    {data?.userID ? data?.userID?.fullName : data?.fullName} - {data?.userID ? data?.userID?.role : data.role} </h2>
                  <h3 className="sm:text-xl text-purple-700">{data?.userID ? data.userID?.email?.id : data?.email?.id}</h3>
                  <div className="text-lg font-semibold">Phone: {data.userID?.phone ?? data?.phone}</div>
                </div>

                {data.vehicle &&
                  <div className="mt-7">
                    <h1 className="font-semibold my-1">Vehicle Information</h1>
                    <div className="border border-green-600 bg-green-600 p-2 bg-opacity-15 rounded-lg w-80 ">
                      <div className="flex justify-between mt-2">
                        <span className="text-sm text-gray-600">Plate Number:</span>
                        <span className="text-sm font-semibold ml-2 text-right capitalize">{data?.vehicle.plateNumber || '***'}</span>
                      </div>
                      <div className="flex justify-between mt-2">
                        <span className="text-sm text-gray-600">Vehicle Model:</span>
                        <span className="text-sm font-semibold ml-2 text-right capitalize">{data?.vehicle.vehicleModel || '***'}</span>
                      </div>
                      <div className="flex justify-between mt-2">
                        <span className="text-sm text-gray-600">Vehicle Type:</span>
                        <span className="text-sm font-semibold ml-2 text-right capitalize">{data?.vehicle.vehicleType || '***'}</span>
                      </div>
                    </div>
                  </div>
                }
              </div>

              {
                (data.vehicle || ['remitter', 'enforcer', 'registrar'].includes(data.role)) &&
                <div className="flex space-x-5 mt-5">
                  <div className="flex items-center space-x-2">
                    <BiSolidTagAlt size={20} className="text-green-400" />
                    <span>TIN: </span>
                    <span className="capitalize">{data.serialID || '***'}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    <BiSolidTagAlt size={20} className="text-green-400" />
                    <span>Route: </span>
                    <span className="capitalize">{data.route}</span>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>

      {/* Bio Info */}
      {(data.vehicle || ['remitter', 'enforcer', 'registrar'].includes(data.role)) &&
        <div className="mt-3 bg-white p-4 rounded-lg grid sm:grid-cols-2 gap-16">
          <div>
            <h3 className="text-md font-semibold text-gray-700">Bio Information</h3>
            <div className="flex justify-between items-center mt-2">
              <span className="text-sm text-gray-600">Gender:</span>
              <span className="text-sm font-semibold text-right capitalize">{data?.gender || '***'}</span>
            </div>
            <div className="flex justify-between items-center mt-2">
              <span className="text-sm text-gray-600">Date of Birth:</span>
              <span className="text-sm font-semibold text-right">{formatDate(data.dob) || '***'}</span>
            </div>
            <div className="flex justify-between items-center mt-2">
              <span className="text-sm text-gray-600">Marital Status:</span>
              <span className="text-sm font-semibold text-right capitalize">{data.maritalStatus || '***'}</span>
            </div>
          </div>

          <div>
            <h3 className="text-md font-semibold text-gray-700">Contact Information</h3>
            <div className="flex justify-between mt-2">
              <span className="text-sm text-gray-600">Address:</span>
              <span className="text-sm font-semibold ml-2 text-right">{data?.address || '***'}</span>
            </div>
          </div>
        </div>
      }

      {/* identity Info */}
      {data?.identity &&
        <div className="mt-3 bg-white p-4 rounded-lg ">
          <div className="grid sm:grid-cols-3 gap-5">
            <div>
              <h3 className="text-md font-semibold text-gray-700">Indentification</h3>
              <div className="flex justify-between items-center mt-2">
                <span className="text-sm text-gray-600">ID Type:</span>
                <span className="text-sm font-semibold text-right uppercase">{data?.identity.Name || '***'}</span>
              </div>
            </div>
            <div>
              <h3 className="text-md font-semibold text-gray-700">Front</h3>
              <div className="bg-gray-400 flex justify-center items-center rounded text-gray-300 mx-auto">
                {data.identity ? (
                  <img src={data.identity.front?.url} alt="profile pix" width={'100%'} height={'100%'} className="cursor-pointer" onClick={() => window.open(data.identity.front?.url, '_blank')} />
                ) : (
                  <BiUser fontSize={25} />
                )}
              </div>
            </div>
            <div>
              <h3 className="text-md font-semibold text-gray-700">back</h3>
              <div className="bg-gray-400 flex justify-center items-center rounded text-gray-300 mx-auto">
                {data.identity ? (
                  <img src={data.identity.back?.url} alt="profile pix" width={'100%'} height={'100%'} className="cursor-pointer" onClick={() => window.open(data.identity.back?.url, '_blank')} />
                ) : (
                  <BiUser fontSize={25} />
                )}
              </div>
            </div>
          </div>
        </div>
      }

      {/* Duties Info */}
      {data.guarantor && data.guarantor?.identity &&
        <div className="mt-3 bg-white p-4 rounded-lg space-y-3">
          <h3 className="text-md font-semibold text-gray-700">Guarantor's Information</h3>
          <div className="flex justify-between items-center mt-2">
            <span className="text-sm text-gray-600">Address:</span>
            <span className="text-sm font-semibold text-right">{data?.guarantor?.address || '***'}</span>
          </div>
          <div className="flex justify-between items-center mt-2">
            <span className="text-sm text-gray-600">Name:</span>
            <span className="text-sm font-semibold text-right">{data?.guarantor.name || '***'}</span>
          </div>
          <div className="flex justify-between items-center mt-2">
            <span className="text-sm text-gray-600">Phone:</span>
            <span className="text-sm font-semibold text-right">{data?.guarantor.phone || '***'}</span>
          </div>
          <div className="grid sm:grid-cols-2 gap-5">
            <div>
              <h3 className="text-md font-semibold text-gray-700">Front</h3>
              <div className="bg-gray-400 flex justify-center items-center rounded text-gray-300 mx-auto">
                {data.guarantor.identity ? (
                  <img src={data.guarantor.identity.front?.url} alt="profile pix" width={'100%'} height={'100%'} className="cursor-pointer" onClick={() => window.open(data.guarantor?.identity?.front?.url, '_blank')} />
                ) : (
                  <BiUser fontSize={25} />
                )}
              </div>
            </div>
            <div>
              <h3 className="text-md font-semibold text-gray-700">back</h3>
              <div className="bg-gray-400 flex justify-center items-center rounded text-gray-300 mx-auto">
                {data.guarantor.identity ? (
                  <img src={data.guarantor?.identity.back?.url} alt="profile pix" width={'100%'} height={'100%'} className="cursor-pointer" onClick={() => window.open(data.guarantor.identity.back?.url, '_blank')} />
                ) : (
                  <BiUser fontSize={25} />
                )}
              </div>
            </div>
          </div>
        </div>
      }

      {data.qrCode &&
        <div className="mt-3 bg-white p-4 rounded-lg justify-center">
          <h3 className="text-md font-semibold text-gray-700">QR Codes</h3>
          <div className="flex flex-col justify-center cursor-pointer items-center">
            <div className="flex justify-end items-center relative">
              <div className="font-bold transform -rotate-90 absolute right-24 w-full bottom-32">EBS / {data.userID?.userCode}</div>
              <img src={data?.qrCode} alt="profile pix" width={'100'} height={'100'} className="w-48" />
            </div>
            <div className="w-32">
              <Button bgColor={'bg-blue rounded-lg text-sm'} title={'Print QR code'} callToAction={() => handlePrint(data?.qrCode)} />
            </div>
          </div>
        </div>
      }


      {data.ticketSubscription &&
        (<>
          {/* Subscription Status */}
          <div className="mt-3 bg-white p-4 rounded-lg ">
            <h3 className="text-md font-semibold text-gray-700">Subscription Status</h3>
            <div className="flex justify-between items-center mt-2">
              <span className="text-sm text-gray-600">Status:</span>
              <span className={`text-sm font-semibold capitalize text-right ${data.ticketSubscription?.subscriptionStatus === 'active' ? 'text-green-500' : 'text-red'}`}>
                {data.ticketSubscription?.subscriptionStatus || '***'}
              </span>
            </div>
            <div className="flex justify-between items-center mt-2">
              <span className="text-sm text-gray-600">Expiring Date:</span>
              <span className="text-sm font-semibold text-right">{data.ticketSubscription ? formatDate(data.ticketSubscription?.subscriptionExpiryDate) : '***'}</span>
            </div>
          </div>

          {/* Payment & Penalty */}
          <div className="mt-3 bg-white p-4 rounded-lg ">
            <h3 className="text-md font-semibold text-gray-700">Payment Information</h3>
            <div className="flex justify-between items-center mt-2">
              <span className="text-sm text-gray-600">Payment Plan:</span>
              <span className="text-sm font-semibold text-right">{data.ticketSubscription?.subscription?.name || '***'}</span>
            </div>
            <div className="flex justify-between items-center mt-2">
              <span className="text-sm text-gray-600">Penalty Charges:</span>
              <span className="text-sm font-semibold text-right"> {`${data?.ticketSubscription?.outstandingPayment ? '₦' + new Intl.NumberFormat("en-NG").format(data?.ticketSubscription?.outstandingPayment) : '***'}`}</span>
            </div>
            <div className="flex justify-between items-center mt-2">
              <span className="text-sm text-gray-600">Renewal Date:</span>
              <span className="text-sm font-semibold text-right">{data.ticketSubscription ? formatDate(data.ticketSubscription?.createdAt) : '***'}</span>
            </div>
          </div>
        </>)
      }
    </div>
  )
}

export default Details