import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/auth/authSlice';
import { clearError } from '../redux/auth/errorSlice';
import { useNavigate } from 'react-router-dom';

const Logout = forwardRef(({ onLogout, className }, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('accessToken');
    navigate('/login');
    dispatch(clearError());
  };

  return (
    <button ref={ref} onClick={onLogout || handleLogout} className="text-white hover:bg-yellow block cursor-pointer rounded-sm px-4 py-2 w-full text-left">
      Logout
    </button>
  );
});

export default Logout;
