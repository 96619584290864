import { Bar } from "react-chartjs-2";

const PaidMotoristChart = ({ percent }) => {

  return (
    <div className="overflow-x-auto">
      <div className="w-[30rem] mx-auto PX-4">
        <Bar
          data={{
            labels: [],
            datasets: [
              {
                label: "Total Revenue Generated",
                data: percent,
                backgroundColor: "#5A6ACF",
              },

            ],
          }}
        ></Bar>
      </div>
    </div>

  )

}

export default PaidMotoristChart;