const Button = ({ callToAction, title, type, disabled, bgColor, Href }) => {
  return (
    <button
      className={`uppercase text-sm ${bgColor ? bgColor : 'bg-blue'} rounded-2xl p-2 text-white w-full my-4 h-12`}
      title = { title }
      onClick={callToAction}
      disabled={disabled}
      to={Href}
    >{title}</button>
  )
};

export default Button;