import React, { useState, useRef } from 'react';
import { MdStop, MdPlayArrow } from 'react-icons/md'; // Import icons

const ImageUpload = ({ title, subtitle, handleImageChange, imagePreview }) => {
  const videoRef = useRef(null); // Reference to the video element
  const canvasRef = useRef(null); // Reference to the canvas element
  const [isWebcamActive, setIsWebcamActive] = useState(false); // State to track webcam activity

  const startWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
      setIsWebcamActive(true);
    } catch (error) {
      console.error('Error accessing webcam: ', error);
    }
  };

  const stopWebcam = () => {
    if (videoRef.current?.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
      setIsWebcamActive(false);
    }
  };

  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (video) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const dataUrl = canvas.toDataURL('image/png');

      // Convert data URL to Blob
      const blob = dataURLToBlob(dataUrl);
      const file = new File([blob], 'captured-image.png', { type: 'image/png' });

      // Call handleImageChange with a simulated file object
      handleImageChange({ target: { files: [file] } });
      stopWebcam(); // Stop webcam after capturing
    }
  };

  // Utility function to convert Data URL to Blob
  const dataURLToBlob = (dataUrl) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  return (
    <div>
      <h1 className="text-2xl text-center mb-4">
        {title}
        <small className="block text-white text-base pt-2">{subtitle}</small>
      </h1>
      <div className="relative max-w-[205px] mx-auto my-12">
        {/* Start/Stop Webcam Button */}
        <div className="absolute right-3 z-10 top-2">
          <label
            onClick={!isWebcamActive ? startWebcam : captureImage}
            className={`w-8 h-8 mb-0 rounded-full bg-white border ${!isWebcamActive ? 'border-blue' : 'border-red'} shadow-[0_4px_10px_rgba(0,0,0,0.03)] cursor-pointer transition duration-200 ease-in-out hover:bg-gray-300 hover:border-gray-300 flex items-center justify-center`}
          >
            {!isWebcamActive ? <MdPlayArrow className="text-blue" /> : <MdStop className="text-red" />}
          </label>
        </div>
        {/* Image/Video/Canvas Display */}
        <div className="w-[192px] h-[192px] relative rounded-full border-6 border-gray-300 shadow-[0_4px_10px_rgba(0,0,0,0.03)]">
          <div className="w-full h-full rounded-full bg-cover bg-center" style={{ backgroundImage: `url(${imagePreview})` }}>
            {/* Video Element (Webcam Stream) */}
            <video ref={videoRef} className={`${isWebcamActive ? 'block' : 'hidden'} w-full h-full object-cover`} style={{ borderRadius: '50%' }} />
            {/* Canvas Element (Capture Image) */}
            <canvas ref={canvasRef} className="hidden" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
