import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axios.service';

// Thunk for fetching data
export const regSub = createAsyncThunk(
  'sub/regSub',
  async (credentials, thunkAPI) => {
    // Convert email to lowercase
    // List of fields to convert to lowercase
    const fieldsToLowerCase = ['name', 'price', 'vehicleType'];

    fieldsToLowerCase.forEach(field => {
      if (credentials[field] && typeof credentials[field] === 'string') {
        credentials[field] = credentials[field].toLowerCase();
      }
    });

    // Validation function to check if fields are empty
    function validateFields(data) {
      const errors = {};

      // Loop through each field and check if it's empty
      Object.keys(data).forEach(field => {
        if (typeof data[field] === 'string') {
          // For strings, check if the trimmed string is empty
          if (!data[field] || data[field].trim() === '') {
            errors[field] = `${field} is required`;
          }
        } else if (data[field] instanceof File) {
          // For files, check if they are undefined or null
          if (!data[field]) {
            errors[field] = `${field} is required`;
          }
        } else {
          // Check for other possible data types (if needed)
          if (!data[field]) {
            errors[field] = `${field} is required`;
          }
        }
      });

      return errors;
    }

    // Perform validation to check for empty fields
    const validationErrors = validateFields(credentials);

    if (Object.keys(validationErrors).length > 0) {
      return thunkAPI.rejectWithValue({ message: 'All field are required' });
    }

    try {
      const response = await axios.post('/api/subscription', credentials);
      // Return the data if the response is successful
      if (response && response.data) {
        return response.status;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {

      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Thunk for fetching data
export const fetchSub = createAsyncThunk(
  'sub/fetchSub',
  async ({ searchQuery }, thunkAPI) => {

    try {
      // Make the dashboard request with the Authorization header
      const response = await axios.get('/api/subscription', {
        params: { types: searchQuery }
      });
      // Return the data if the response is successful
      if (response && response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {
      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Thunk for fetching data
export const updateSub = createAsyncThunk(
  'sub/updateSub',
  async (id, thunkAPI) => {

    try {
      // Make the dashboard request with the Authorization header
      const response = await axios.patch(`/api/subscription/${id}`);
      // Return the data if the response is successful
      if (response && response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {
      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Thunk for fetching data
export const deleteSub = createAsyncThunk(
  'sub/deleteSub',
  async (id, thunkAPI) => {

    try {
      // Make the dashboard request with the Authorization header
      const response = await axios.delete(`/api/subscription/${id}`);
      // Return the data if the response is successful
      if (response && response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {
      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const subSlice = createSlice({
  name: 'subscription',
  initialState: {
    data: [],
    loading: false,
    error: null,
    searchQuery: 'keke',
  },
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSub.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSub.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchSub.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(regSub.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(regSub.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(regSub.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateSub.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSub.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(updateSub.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteSub.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSub.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(deleteSub.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setSearchQuery } = subSlice.actions;

export default subSlice.reducer;
