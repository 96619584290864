import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  // Function to check if the user is logged in
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true'; // Explicitly checking for 'true' as string
  const token = localStorage.getItem('accessToken');

  // Return true if both isLoggedIn and token exist
  return !!(isLoggedIn && token);
};

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const isAuthenticated = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      // Redirect to login if not authenticated
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  // Render the children only if the user is authenticated
  return isAuthenticated ? children : null;
};

export default AuthGuard;
