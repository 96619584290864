import React from 'react';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const createPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  };

  const pages = createPageNumbers();

  return (
    <div className="flex justify-end">
      <div className="container mx-auto px-4 mt-10">
        <nav className="flex flex-row flex-nowrap justify-between md:justify-center items-center" aria-label="Pagination">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="flex w-10 h-10 mr-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-300"
            title={'Previous ' + currentPage}
          >
            <span className="sr-only">Previous Page</span>
            <svg className="block w-4 h-4 fill-current" viewBox="0 0 256 512" aria-hidden="true" role="presentation">
              <path d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"></path>
            </svg>
          </button>
          {pages.map((page) => (
            <button
              onClick={() => onPageChange(page)}
              key={page}
              className={`hidden ${currentPage === page
                  ? 'border-black bg-black text-white pointer-events-none'
                  : 'border-gray-200 bg-white text-black  hover:border-gray-300'
                } md:flex w-10 h-10 mx-1 justify-center items-center rounded-full border`}
              title={'Page ' + page}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={totalPages === currentPage}
            className="flex w-10 h-10 ml-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-300"
            title={'Next ' + totalPages}
          >
            <span className="sr-only">Next Page</span>
            <svg className="block w-4 h-4 fill-current" viewBox="0 0 256 512" aria-hidden="true" role="presentation">
              <path d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path>
            </svg>
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Pagination;
