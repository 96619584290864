import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axios.service';

// Thunk for fetching data
export const fetchProfile = createAsyncThunk(
  'data/fetchProfile',
  async (thunkAPI) => {

    try {
      // Make the dashboard request with the Authorization header
      const response = await axios.get(`/api/profile`);

      // Return the data if the response is successful
      if (response && response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {
      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Thunk for fetching data
export const fetchUpdateProfile = createAsyncThunk(
  'data/fetchUpdated',
  async (credentials, thunkAPI) => {

    // List of fields to convert to lowercase
    const fieldsToLowerCase = ['fullName', 'phone', 'maritalStatus', 'address'];

    fieldsToLowerCase.forEach(field => {
      if (credentials[field] && typeof credentials[field] === 'string') {
        credentials[field] = credentials[field].toLowerCase();
      }
    });

    try {
      // Make the dashboard request with the Authorization header
      const response = await axios.post(`/api/profile/${credentials?.id}`, credentials);

      // Return the data if the response is successful
      if (response && response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {
      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const userSlice = createSlice({
  name: 'data',
  initialState: {
    data: [],
    loading: false,
    error: null,
    status: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.status = 'failed';
      })
      .addCase(fetchUpdateProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUpdateProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.status = 'successfull';
      })
      .addCase(fetchUpdateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.status = 'failed';
      });
  },
});

export default userSlice.reducer;
