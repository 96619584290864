import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import errorReducer from './auth/errorSlice';
import dashboardReducer from './authenticated/dashboardSlice';
import performingAgentReducer from './authenticated/performingAgentySlice';
import adminReducer from './authenticated/adminSlice';
import managerReducer from './authenticated/managerSlice';
import remitterReducer from './authenticated/remitterSlice';
import enforcerReducer from './authenticated/enforcerSlice';
import registrarReducer from './authenticated/registrarSlice';
import unionReducer from './authenticated/unionSlice';
import motoristReducer from './authenticated/motoristSlice';
import userSlice from './authenticated/userSlice';
import subSlice from './authenticated/subscriptionSlice';
import profileSlice from './authenticated/profileSlice';
import paymentHistory from './authenticated/paymentHistorySlice';
import scanReducer from './authenticated/scanHistorySlice';
import allocationReducer from './authenticated/allocationSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    error: errorReducer,
    dashboard: dashboardReducer,
    admin: adminReducer,
    manager: managerReducer,
    remitter: remitterReducer,
    enforcer: enforcerReducer,
    registrar: registrarReducer,
    union: unionReducer,
    motorist: motoristReducer,
    user: userSlice,
    subscription: subSlice,
    profile: profileSlice,
    transaction: paymentHistory,
    performingAgent: performingAgentReducer,
    allocation: allocationReducer,
    scan: scanReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true, // This enables redux-thunk by default
    }),
});
