import { NavLink, useLocation } from "react-router-dom";
import Navigation from "../layouts/Navigation";
import classNames from "classnames";
import Logo from "../assets/images/eticket-logo-removebg-preview.png";
import { useState } from "react";

const linkClasses1 =
  "flex items-center p-2 md:py-3 rounded-lg";

const linkClasses2 =
  "flex items-center px-2 py-2 md:py-3 hover:bg-yellow hover:text-blue rounded-lg";

const getActive = (path, pathname) => {
  return path === pathname
};

const Sidebar = () => {

  return (
    <div>
      <div className={'sidebar bg-blue bottom-0 fixed top-0 w-[16rem] space-y-5 text-[#E5E5E5] px-3 overflow-y-auto py-5 lg:flex flex-col hidden'}>
        <div className="flex items-center space-x-3">
          <img src={Logo} alt="logo" className="w-1/6" />
          <span className="text-xl font-bold">E-Ticketing</span>
        </div>
        <div>
          {Navigation.map((item) => (
            <SidebarLink key={item.key} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

function SidebarLink({ item }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { pathname } = useLocation();

  const active = getActive(item.fullPath, pathname);
  const activeSubLinks = item.subLinks?.some((subLink) => getActive(subLink.fullPath, pathname));

  return (
    <div>
      <NavLink
        to={item.path}
        onClick={() => item.subLinks && setIsExpanded(!isExpanded)}
        className={active ? classNames("font-medium text-[16px] space-x-3 bg-yellow text-blue", linkClasses1) : classNames(item.role ? "hidden" : "text-[17px] space-x-3", item.department ? 'hidden' : '', linkClasses2)}
      >
        <span>{item.icon}</span>
        <span>{item.label}</span>
      </NavLink>

      {/* Sub Links */}
      {item.subLinks && isExpanded && (
        <div className="pl-6 mt-2 space-y-1">
          {item.subLinks.map((subLink, index) => (
            <NavLink
              key={index}
              to={subLink.path}
              className={activeSubLinks ? classNames("font-medium text-[14px] space-x-3 text-yellow", linkClasses1) : classNames(item.role ? "hidden" : "text-[17px] space-x-3", item.department ? 'hidden' : '', linkClasses2)}
            >
              <span>{subLink.icon}</span>
              <span>{subLink.label}</span>
            </NavLink>
          ))}
        </div>
      )}
    </div>

  );
}
