import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axios.service';

// Thunk for fetching data
export const fetchAllocation = createAsyncThunk(
  'allocate/fetchAllocation',
  async (thunkAPI) => {

    try {
      // Make the dashboard request with the Authorization header
      const response = await axios.get(`/api/allocation`);

      // Return the data if the response is successful
      if (response && response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {
      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Thunk for fetching data
export const fetchStakeHolders = createAsyncThunk(
  'allocate/fetchStakeHolder',
  async (thunkAPI) => {

    try {
      // Make the dashboard request with the Authorization header
      const response = await axios.get(`/api/stakeholders`);

      // Return the data if the response is successful
      if (response && response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {
      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Thunk for fetching data
export const fetchBank = createAsyncThunk(
  'allocate/fetchBank',
  async ({ bank_code, accountNumber }, thunkAPI) => {

    try {
      // Make the dashboard request with the Authorization header
      const response = await axios.get(`/api/searchForBank`, {
        params: { bank_code, accountNumber }
      });

      // Return the data if the response is successful
      if (response && response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {
      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Thunk for fetching data
export const postStakeHolder = createAsyncThunk(
  'allocate/postPercentage',
  async (credentials, thunkAPI) => {
    // List of fields to convert to lowercase
    const fieldsToLowerCase = ['bankName', 'accountName'];

    fieldsToLowerCase.forEach(field => {
      if (credentials[field] && typeof credentials[field] === 'string') {
        credentials[field] = credentials[field].toLowerCase();
      }
    });

    // Validation function to check if fields are empty
    function validateFields(data) {
      const errors = {};

      // Loop through each field and check if it's empty
      Object.keys(data).forEach(field => {
        if (typeof data[field] === 'string') {
          // For strings, check if the trimmed string is empty
          if (!data[field] || data[field].trim() === '') {
            errors[field] = `${field} is required`;
          }
        } else if (data[field] instanceof File) {
          // For files, check if they are undefined or null
          if (!data[field]) {
            errors[field] = `${field} is required`;
          }
        } else {
          // Check for other possible data types (if needed)
          if (!data[field]) {
            errors[field] = `${field} is required`;
          }
        }
      });

      return errors;
    }

    // Perform validation to check for empty fields
    const validationErrors = validateFields(credentials);

    if (Object.keys(validationErrors).length > 0) {
      return thunkAPI.rejectWithValue({ message: 'All field are required' });
    }

    try {
      const response = await axios.post('/api/stakeholders', credentials);
      // Return the data if the response is successful
      if (response && response.data) {
        return response.status;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {


      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Thunk for fetching data
export const updateStakeHolder = createAsyncThunk(
  'allocate/updatePercentage',
  async (credentials, thunkAPI) => {
    const { percentage, id } = credentials;
    // Validation function to check if fields are empty
    if (credentials.percentage === '') {
      return thunkAPI.rejectWithValue({ message: 'All field are required' });
    }

    try {
      const response = await axios.patch(`/api/stakeholders/${id}`, { percentage });
      // Return the data if the response is successful
      if (response && response.data) {
        return response.status;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {


      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const userSlice = createSlice({
  name: 'data',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllocation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllocation.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchAllocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchStakeHolders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStakeHolders.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(fetchStakeHolders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || action.payload.message;
      })
      .addCase(fetchBank.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBank.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(fetchBank.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(postStakeHolder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postStakeHolder.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(postStakeHolder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || action.payload.message;
      })
      .addCase(updateStakeHolder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateStakeHolder.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(updateStakeHolder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || action.payload.message;
      });
  },
});

export default userSlice.reducer;
