import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/auth/authSlice';
import { clearError } from '../redux/auth/errorSlice';
import ErrorModal from '../components/ErrorsModal';  // The modal component we created earlier
import { useNavigate } from 'react-router-dom';

const ShowError = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message, type } = useSelector((state) => state.error);

  const closeModal = () => {
    if (type === 'network') {
      dispatch(clearError());
    } else {
      dispatch(clearError());
      dispatch(logout());
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('accessToken');
      navigate('/login');
    }
  };

  return (
    <>
      {/* Render ErrorModal only if there's an error */}
      {message && (
        <ErrorModal message={message} onClose={closeModal} />
      )}
      {/* Other component logic */}
    </>
  );
};

export default ShowError;
