import React from 'react';
import { Outlet } from 'react-router-dom';
import EticketingLogo from '../../assets/images/eticket-logo-removebg-preview.png';

const AuthIndex = () => {

  return (
    <div className="flex min-h-screen overflow-hidden relative">
      <div className="bg-blue hidden md:flex w-1/2 h-screen overflow-y-hidden items-center">
        <img src={EticketingLogo} alt="login" className="object-cover w-1/2 mx-auto" />
      </div>
      <div className="login-card bg-white px-8 overflow-y-hidden mx-auto flex flex-col justify-center w-[45rem] h-screen">
        <div className="w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AuthIndex;