import React from 'react';

const ConfirmModal = ({ show, children, message, onClose }) => {
  if (!show) return null
  return (
    <div className="fixed overflow-y-auto overflow-x-hidden top-0 right-0 left-0 inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-light-gray-col rounded-lg shadow relative p-4 w-full max-w-md max-h-full mx-2">
        <button onClick={onClose} type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white">
          <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <div className="p-4 md:p-5 text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-400">{message}</h3>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
