// eslint-disable-next-line
import { Chart as ChartJS } from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { useState, useEffect } from "react";

import { useDispatch } from 'react-redux';
import { RevenueChart } from '../redux/authenticated/dashboardSlice';

const RevenuePieChart = () => {
  const dispatch = useDispatch();

  const [unionName, setUnionName] = useState([]);
  const [unionPayment, setUnionPayments] = useState([]);

  useEffect(() => {
    dispatch(RevenueChart()).then(res => {
      const unionNames = res.payload ? res.payload?.map(data => data.name) : [];
      const unionPayments = res.payload ? res.payload?.map(data => data.payment) : [];
      setUnionName(unionNames);
      setUnionPayments(unionPayments);
    }).catch(err => {
      return err
    })

  }, [dispatch])

  // Generate random colors
  const generateRandomColors = (numColors) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const color = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
      colors.push(color);
    }
    return colors;
  };

  return (
    <div className="text-gray-300 revenuepie shadow-[0_4px_10px_rgba(0,0,0,0.03)] p-4 rounded-md mb-4 flex flex-col bg-blue ">
      <div className="flex flex-col mb-4">
        <span className="text-sm md:text-xl mb-4 ">Revenue Allocation</span>
      </div>
      <div className="xl:w-80 w-60 mx-auto ">
        {unionName && unionName &&
          <Doughnut
            data={{
              labels: unionName,
              datasets: [
                {
                  label: "₦",
                  data: unionPayment,
                  backgroundColor: generateRandomColors(unionPayment.length), // dynamically generate colors
                },
              ],
            }}
          ></Doughnut>
        }
      </div>
    </div>
  );
};

export default RevenuePieChart;
