import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import ShowError from "../errors/showErrorModal";
import Header from "../components/Header";

const AdminLayout = () => {

  return (
    <div className={`admin-layout lg:grid grid-cols-10 bg-[#e5e7eb]`}>
      <Header />
      <div className="xl:col-span-2 lg:col-span-3 overflow-hidden z-10">
        <Sidebar />
      </div>
      <div className="xl:col-span-8 lg:col-span-7 py-9 w-full pr-10 lg:pl-0 pl-10 bg-[#e5e7eb] relative h-screen overflow-y-auto">
        <div> {<Outlet />} </div>
        <ShowError />
      </div>
    </div>
  );
}
export default AdminLayout;