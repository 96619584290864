import DashboardGrid from "../../components/DashboardGrid";
import TransactionChart from "../../components/TransactionChart";
import RevenuePieChart from "../../components/RevenuePieChart";
import TopPerformingAgentsRecord from "../../components/TopPerformingAgentsRecord";
import Loader from "../../layouts/Loader";
import { useDispatch } from "react-redux";
import { dashboard } from "../../redux/authenticated/dashboardSlice";
import { useState } from "react";

const Dashboard = () => {
  document.title = "e-Ticketing | Dashboard"
  const dispatch = useDispatch()
  const [loading, setLoading] = useState('true')

  dispatch(dashboard()).then(() => {
    setLoading(false)
  });

  return (
    <>
      {loading ?
        <Loader /> :
        <div>
          <div className="flex flex-col md:flex-row items-center justify-between mx-6 lg:mx-2 mt-8">
            <div className="flex flex-col py-6 mt-10 md:mt-6 text-center md:text-left">
              <span className="text-xl md:text-2xl text-black mb-2 font-medium">Welcome Admin</span>
              <span className="text-sm md:text-md text-black">
                Here’s an overview of today’s activities
              </span>
            </div>
          </div>
          <div className="mx-2">
            <DashboardGrid />
          </div>

          <div className="w-full mt-12">
            <TransactionChart />
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 w-full ">
            <div className="lg:col-span-2 hidden md:block">
              <TopPerformingAgentsRecord />
            </div>
            <div className="md:col-span-1 col-span-2">
              <RevenuePieChart />
            </div>
          </div>
        </div>
      }
    </>

  );
};

export default Dashboard;
