// import RevenueHistoryRecord from "../../components/RevenueHistoryRecord";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Button from "../../components/Button";
import ConfirmModal from '../../components/ConfirmModal';
import { Spinal } from '../../assets/icons/extIcons';
import { fetchStakeHolders, fetchBank, postStakeHolder, updateStakeHolder } from "../../redux/authenticated/allocationSlice";
import { fetchUnion } from '../../redux/authenticated/unionSlice';
import SubscriptionGrid from "../../components/SubscriptionGrid";
import { useNavigate } from "react-router-dom";

const Allocation = () => {
  document.title = "e-Ticketing | Allocation"

  const navigate = useNavigate()

  const dispatch = useDispatch();

  const { loading, error } = useSelector(state => state.allocation);

  const [stakeholder, setStakeHolder] = useState({})
  const [getBankNames, setBankNames] = useState()
  const [accountNumber, setBankNumber] = useState('');
  const [storeBankCode, setBankCode] = useState('');
  const [bankHolder, setBankHolder] = useState('');

  const [Errors, setError] = useState();

  const [toggleModal, setToggleModal] = useState(false);
  const [percentage, setPercentage] = useState(0.1); // Initial state at minimum value
  const [partners, setPartners] = useState('');
  const [allStakeholders, setAllStakeholders] = useState([]);
  const [disabled, setDisabled] = useState(false)
  const [id, setId] = useState('');

  useEffect(() => {
    dispatch(fetchUnion({
      currentPage: '', perPage: '', searchQuery: '', startDate: '',
      endDate: ''
    })).then(res => {
      setStakeHolder(res.payload.data)
    });

    dispatch(fetchStakeHolders()).then(res => {
      setAllStakeholders(res.payload)
    });

    if (storeBankCode && accountNumber.length > 9) {
      dispatch(fetchBank({ bank_code: storeBankCode.code, accountNumber: Number(accountNumber) })).then(res => {
        setBankHolder(res.payload.accountName)
      });
    }

    const PAYSTACK_SECRET_KEY = process.env.REACT_APP_PAYSTACK_SECRET_KEY;
    const fetchBankNames = async () => {
      try {
        const response = await axios.get(
          'https://api.paystack.co/bank?currency=NGN',
          {
            headers: {
              Authorization: `Bearer ${PAYSTACK_SECRET_KEY}`,
            },
          }
        );

        if (response.status === 200 && response.data.status) {
          setBankNames(response.data.data);
        } else {
          throw new Error('Could not fetch bank names');
        }
      } catch (error) {
        setError('Could not fetch bank names');
      }
    };
    fetchBankNames()
  }, [dispatch, storeBankCode, accountNumber]);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(postStakeHolder({ accountNumber, bankName: storeBankCode.name, accountName: bankHolder, percentage, name: partners })).then(res => {

      if (res.payload !== 201) {
        setError(res.payload.message)
      }
      dispatch(fetchStakeHolders()).then(res => {
        setAllStakeholders(res.payload)
      });
      setToggleModal(false);
    })
  }

  const handleModal = () => {
    setToggleModal(prevState => !prevState);
    setDisabled(false)
  }

  const updatePlan = (_e) => {
    setId(_e)
    setToggleModal(true);
    setDisabled(true);
  }

  const submitUpdate = (e) => {
    e.preventDefault();
    dispatch(updateStakeHolder({ id, percentage })).then(res => {
      if (res.payload !== 201) {
        setError(res.payload.message)
      }

      dispatch(fetchStakeHolders()).then(res => {
        setAllStakeholders(res?.payload)
      });
      setToggleModal(false);
    });
  }

  const handleDetail = (_e) => {
    navigate(`/allocation/${_e}`, { state: _e })
  }

  return (
    <div className="revenuehistory">
      <div className="md:mt-12 mt-24">

        <ConfirmModal show={toggleModal} onClose={() => setToggleModal(false)}>
          <form onSubmit={disabled ? submitUpdate : handleSubmit}>
            {loading && !error}
            {error && <p className="text-[#FF4C4C] text-sm">{error}</p>}
            {Errors && <p className="text-[#FF4C4C] text-sm">{Errors}</p>}
            <div className="text-left">
              <div className='flex justify-center space-x-2 mb-6 mt-8'>
                <span className="text-light-black font-semibold">{percentage}%</span>
                <input
                  required
                  placeholder='Percentage'
                  type="range"
                  min="0.1"
                  max="80"
                  step="0.1"
                  value={percentage}
                  autoComplete="off"
                  className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                  onChange={(e) => setPercentage(e.target.value)}
                ></input>
              </div>
            </div>

            <div className="flex justify-center space-x-2 mb-6 mt-8">
              <div className='text-left'>
                <select
                  autoComplete="off"
                  className={`text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white w-full ${disabled && 'hidden'}`}
                  onChange={(e) => setPartners(e.target.value)}
                >
                  <option value="">Partner's Name</option>
                  {Array.isArray(stakeholder) && stakeholder.length > 0 ? (
                    stakeholder.map((item, index) => (
                      <option className="capitalize" key={item.id || index} value={item.id}>
                        {item.unionName}
                      </option>
                    ))
                  ) : (
                    <option value="">No data available</option>
                  )}
                </select>
              </div>
              <div className='text-left'>
                <input
                  placeholder='Bank Account Number'
                  type="number"
                  autoComplete="off"
                  className={`text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white ${disabled && 'hidden'}`}
                  onChange={(e) => setBankNumber(e.target.value)}
                ></input>
              </div>
            </div>
            <div className="flex justify-center space-x-2 mb-6 mt-8">
              <div className='text-left'>
                <select
                  autoComplete="off"
                  className={`text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white w-full ${disabled && 'hidden'}`}
                  onChange={(e) => {
                    const selectedBank = getBankNames.find(bank => bank.code === e.target.value);
                    setBankCode(selectedBank);
                  }}
                >
                  <option value="">Select Bank</option>
                  {Array.isArray(getBankNames) && getBankNames.length > 0 ? (
                    getBankNames.map((item, index) => (
                      <option className="capitalize" key={item.id || index} value={item.code}>
                        {item.name}
                      </option>
                    ))
                  ) : (
                    <option value="">No data available</option>
                  )}
                </select>
              </div>
              <div className='text-left'>
                <input
                  disabled
                  placeholder='Account Name'
                  type="text"
                  value={bankHolder}
                  autoComplete="off"
                  className={`text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white ${disabled && 'hidden'}`}
                ></input>
              </div>
            </div>

            <Button disabled={loading ? true : false} title={loading ? <div role="status" className='flex justify-center'> <Spinal /> <span className="sr-only">Loading...</span> </div> : 'Submit'} type={'submit'}></Button>
          </form>
        </ConfirmModal>
        <div className="flex items-center my-3">
          <h3 className='font-semibold text-3xl'>Allocations</h3>
          <div className="w-48 ml-auto">
            <Button title={'Assign Percentage'} bgColor={'block bg-blue text-black sm:font-bold py-2 px-4 rounded'}
              callToAction={handleModal}>
            </Button>
          </div>
        </div>

        <div className="bg-white p-4 ">
          <SubscriptionGrid subscription={allStakeholders} loading={loading} handleDetail={handleDetail} deletePlan={updatePlan} Delete={'Update'} color={'border-yellow'} />
        </div>
      </div>
    </div>
  );
};

export default Allocation;
