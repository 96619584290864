import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import Button from '../../components/Button';
import { Spinal } from '../../assets/icons/extIcons';
import { resetPassword } from '../../redux/auth/authSlice';

const ResetPassword = () => {
  document.title = "e-Ticketing | Reset Password";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const { status, error } = useSelector((state) => state.auth);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(resetPassword({ token, password, confirmPassword })).then(res => {
      if (res.payload !== 200) {
        return false
      }
      navigate('/login')
    }).catch(err => {
      return err
    })
  }

  const isLoading = status === 'loading';
  const hasError = status === 'failed';

  return (

    <form onSubmit={handleSubmit}>
      {isLoading && !hasError}
      {hasError && <p className="text-[#FF4C4C] text-sm">{error?.message}</p>}
      <div className="flex flex-col gap-2 mb-6 mt-8">
        <label className="text-light-black text-sm">Token</label>
        <span className="font-thin text-sm text-light-purple">Please input the token that was sent to your email address here:</span>
        <input
          type="text"
          id="token"
          autoComplete="off"
          className="text-light-black focus:outline-none px-2 py-4 rounded-md bg-dim-white"
          onChange={(e) => setToken(e.target.value)}
          value={token}
        ></input>
      </div>
      <div className="flex flex-col gap-2 mb-6 mt-8">
        <label className="text-light-black text-sm">New Password</label>
        <div className="relative w-full">
          <input
            type={passwordVisible ? 'text' : 'password'}
            id="confirm password"
            autoComplete="off"
            className="text-light-black focus:outline-none px-2 py-4 w-full rounded-md bg-dim-white"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          ></input>
          <span
            onClick={togglePasswordVisibility}
            className="absolute right-3 top-3 cursor-pointer text-gray-500 py-2 "
          >
            {passwordVisible ? <FiEyeOff size={20} /> : <FiEye size={20} />}
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-2 mb-6 mt-8">
        <label className="text-light-black text-sm">Confirm Password</label>
        <div className="relative w-full">
          <input
            type={passwordVisible ? 'text' : 'password'}
            id="confirm password"
            autoComplete="off"
            className="text-light-black focus:outline-none px-2 py-4 w-full rounded-md bg-dim-white"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          ></input>
          <span
            onClick={togglePasswordVisibility}
            className="absolute right-3 top-3 cursor-pointer text-gray-500 py-2 "
          >
            {passwordVisible ? <FiEyeOff size={20} /> : <FiEye size={20} />}
          </span>
        </div>
      </div>

      <Button disabled={isLoading ? true : false} title={isLoading ? <div role="status" className='flex justify-center'> <Spinal /> <span className="sr-only">Loading...</span> </div> : 'Login'} type={'submit'}></Button>
    </form>
  )
}

export default ResetPassword;