import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import { Spinal } from '../../assets/icons/extIcons';
import { forgotPassword, clearError } from '../../redux/auth/authSlice';

const ForgotPassword = () => {
  document.title = "e-Ticketing | Forgot Password"
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  const { status, error } = useSelector((state) => state.auth);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(forgotPassword(email)).then(res => {
      if (res.payload !== 200) {
        return false
      }
      navigate('/login/password-reset');
    }).catch(err => {
      return err
    })
  }

  const login = () => {
    navigate('/login');
    dispatch(clearError()); // This will reset the error state
  }

  const isLoading = status === 'loading';
  const hasError = status === 'failed';

  return (
    <form onSubmit={handleSubmit}>
      {isLoading && !hasError}
      {hasError && <p className="text-[#FF4C4C] text-sm">{error?.message}</p>}
      <span className="text-xl mb-8">Forgot Password</span>
      <div className="flex flex-col gap-2 mb-6 mt-8">
        <label className="text-light-black text-sm">Email</label>
        <input
          type="email"
          id="email"
          autoComplete="off"
          className="text-light-black focus:outline-none px-2 py-4 rounded-md bg-dim-white"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        ></input>
      </div>

      <Button disabled={isLoading ? true : false} title={isLoading ? <div role="status" className='flex justify-center'> <Spinal /> <span className="sr-only">Loading...</span> </div> : 'Submit'} type={'submit'}></Button>
      <div className="flex items-start mb-5">
        <span onClick={login} className="text-sm text-blue cursor-pointer my-4">← return to <span className="font-medium underline">login</span> </span>
      </div>
    </form>
  )
}

export default ForgotPassword;