import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import BASE_URL from '.';
import { store } from '../redux/store';
import { setError, setType } from '../redux/auth/errorSlice';  // Action to update the error state

import { isTokenExpired } from './ExpiredToken';
import setNextRoute from '../routerGuard/SetNextedRoute';

// Get token from localStorage
const token = JSON.parse(localStorage.getItem('accessToken'));

axios.defaults.baseURL = BASE_URL
axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.timeout = 600000;

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (isTokenExpired(jwtDecode, token)) {
    setNextRoute()
    // Dispatch an action to set the error in Redux
    store.dispatch(setError('Session is Expired, Please login'));
  }
  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
    setNextRoute()
    // Dispatch an action to set the error in Redux
    store.dispatch(setError(error.response?.data?.message || 'An unknown error occurred'));
  }
  if (error.message === 'Network Error') {
    setNextRoute()
    // Dispatch an action to set the error in Redux
    store.dispatch(setError('No internet connection'));
    store.dispatch(setType('network'));
  }
  return Promise.reject(error);
});


export default axios;