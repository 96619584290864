import Router from './router';
import './App.css';

function App() {
   return (
      <div className="App">
       <Router />
      </div>
   );
}

export default App;
