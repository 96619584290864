import AllocationRecord from "../../components/AllocationRecord";
import { useLocation } from 'react-router-dom';

const AllocationDetail = ({ type }) => {
  const location = useLocation(); // Access the location object

  const data = location.state;


  return (
    <div className="mt-16">
      <h3 className='font-semibold text-3xl capitalize'>Allocations to</h3>
      <p className="font-thin text-xl mb-11">{data ?? type}</p>

      <AllocationRecord type={data ?? type} />
    </div>
  )
};

export default AllocationDetail;