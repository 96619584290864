import { jwtDecode } from 'jwt-decode';

const token = localStorage.getItem('accessToken');

let decodedToken = null;

if (token) {
  try {
    if (token.split('.').length === 3) {
      decodedToken = jwtDecode(token)
    } else {
      throw new Error('Invalid token format')
    }
  } catch (error) {
    throw new Error('Error decoding token:', error)
  }
}

export const isRole = () => {
  if (decodedToken && decodedToken.user) {
    return decodedToken.user;
  }
  return ''; // Return null or a default role if there's no valid token or role
};