import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AuthIndex from "./pages/auth";
import Login from "./pages/auth/loginUser";
import ForgotPassword from "./pages/auth/Forgotpassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Index from "./pages/admin/Home";
import Admins from "./pages/admin/Admins";
import Managers from "./pages/admin/Managers";
import Remitter from "./pages/admin/Remitter";
import Enforcer from "./pages/admin/Enforcer";
import Registrar from "./pages/admin/Registrar";
import Unions from "./pages/admin/Unions";
import Motorist from './pages/admin/Motorist';
import Subscription from './pages/admin/Subscription';
import Penalty from './pages/admin/Penalty';
import PenaltyHistory from './pages/admin/PenaltyHistory';
import Profile from './pages/admin/Profile';
import ScanRecord from './pages/admin/ScanRecord';
import Details from './pages/admin/Details';
import AllocationDetail from './pages/admin/AllocationDetail';
import NewManager from "./pages/admin/NewManagers";
import NewRemitter from "./pages/admin/NewRemitters";
import NewEnforcer from "./pages/admin/NewEnforcers";
import NewRegistrar from "./pages/admin/NewRegistrar";
import NewMotorist from "./pages/admin/NewMotorists";
import Transaction from "./pages/admin/Transaction";
import Allocation from "./pages/admin/Allocation";
import SystemLayout from "./layouts/systemLayout";
import AlreadyLogin from "./routerGuard/AlreadyLoginGuard";
import ProtectedRoute from './routerGuard/ProtectedRoute';

const ProjectRoutes = () => {
  return (
    <React.Suspense>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<AlreadyLogin><AuthIndex /></AlreadyLogin>} >
            <Route path="" index element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="password-reset" element={<ResetPassword />} />
          </Route>

          {/* Private Routes */}
          <Route path="/" element={<ProtectedRoute><SystemLayout /></ProtectedRoute>}>
            <Route path="" element={<Index />} />
            <Route path="admins" element={<Admins />} />
            <Route path="managers" element={<Managers />} />
            <Route path="remitters" element={<Remitter />} />
            <Route path="enforcers" element={<Enforcer />} />
            <Route path="registrars" element={<Registrar />} />
            <Route path="unions" element={<Unions />} />
            <Route path="motorists" element={<Motorist />} />
            <Route path="subscriptions" element={<Subscription />} />
            <Route path="penalty" element={<Penalty />} />
            <Route path="penaltyHistory" element={<PenaltyHistory />} />
            <Route path="Profile" element={<Profile />} />
            <Route path="transaction" element={<Transaction />} />
            <Route path="scan" element={<ScanRecord />} />
            <Route path="allocation" element={<Allocation />} />

            <Route path=":id/details" element={<Details />} />
            <Route path="allocation/:id" element={<AllocationDetail />} />

            <Route path="newmanager" element={<NewManager />} />
            <Route path="newremitter" element={<NewRemitter />} />
            <Route path="newenforcer" element={<NewEnforcer />} />
            <Route path="newregistrar" element={<NewRegistrar />} />
            <Route path="newmotorist" element={<NewMotorist />} />
            
          </Route>

          {/* Catch-All Route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};

export default ProjectRoutes;
