import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axios.service';

// Thunk for fetching data
export const regAgent = createAsyncThunk(
  'manager/regAgent',
  async (credentials, thunkAPI) => {
    // Convert email to lowercase
    // List of fields to convert to lowercase
    const fieldsToLowerCase = ['email', 'password', 'fullName', 'phone', 'idType', 'maritalStatus', 'address', 'route', 'role'];

    fieldsToLowerCase.forEach(field => {
      if (credentials[field] && typeof credentials[field] === 'string') {
        credentials[field] = credentials[field].toLowerCase();
      }
    });

    // Validation function to check if fields are empty
    function validateFields(data) {
      const errors = {};

      // Loop through each field and check if it's empty
      Object.keys(data).forEach(field => {
        if (typeof data[field] === 'string') {
          // For strings, check if the trimmed string is empty
          if (!data[field] || data[field].trim() === '') {
            errors[field] = `${field} is required`;
          }
        } else if (data[field] instanceof File) {
          // For files, check if they are undefined or null
          if (!data[field]) {
            errors[field] = `${field} is required`;
          }
        } else {
          // Check for other possible data types (if needed)
          if (!data[field]) {
            errors[field] = `${field} is required`;
          }
        }
      });

      return errors;
    }

    // Perform validation to check for empty fields
    const validationErrors = validateFields(credentials);

    if (Object.keys(validationErrors).length > 0) {
      return thunkAPI.rejectWithValue({ message: 'All field are required' });
    }

    // Create a FormData object
    const formData = new FormData();

    // Append all fields from credentials to FormData
    Object.keys(credentials).forEach(key => {
      if (credentials[key]) {
        formData.append(key, credentials[key]);
      }
    });

    try {
      const response = await axios.post('/api/agent/register', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // Return the data if the response is successful
      if (response && response.data) {
        return response.status;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {


      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

// Thunk for fetching data
export const fetchAgent = createAsyncThunk(
  'manager/fetchAgent',
  async ({ currentPage, perPage, searchQuery, active, startDate, endDate }, thunkAPI) => {

    try {
      // Make the dashboard request with the Authorization header
      const response = await axios.get('/api/agent/user', {
        params: { page: currentPage, limit: perPage, search: searchQuery, online: active, startDate, endDate, role: 'registrar' },
      });
      // Return the data if the response is successful
      if (response && response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {
      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const agentSlice = createSlice({
  name: 'agent',
  initialState: {
    data: [],
    sortConfig: { key: '', direction: 'asc' },
    searchQuery: '',
    currentPage: 1,
    perPage: 10,
    totalPages: 1,
    selectedItems: [],
    active: undefined,
    startDate: '',
    endDate: '',
    loading: false,
    error: null,
  },
  reducers: {
    setSortConfig: (state, action) => {
      state.sortConfig = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setActiveQuery: (state, action) => {
      state.active = action.payload;
    },
    setStartDateQuery: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDateDateQuery: (state, action) => {
      state.endDate = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    toggleSelectItem: (state, action) => {
      if (state.selectedItems.includes(action.payload)) {
        state.selectedItems = state.selectedItems.filter(item => item !== action.payload);
      } else {
        state.selectedItems.push(action.payload);
      }
    },
    clearSelectedItems: (state) => {
      state.selectedItems = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.totalPages = Math.ceil(action.payload.paginator.pageCount);
      })
      .addCase(fetchAgent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(regAgent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(regAgent.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(regAgent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setSortConfig,
  setSearchQuery,
  setCurrentPage,
  setPerPage,
  toggleSelectItem,
  setActiveQuery,
  setStartDateQuery,
  setEndDateDateQuery,
  clearSelectedItems
} = agentSlice.actions;

export default agentSlice.reducer;
