import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import SubscriptionGrid from "../../components/SubscriptionGrid";
import Button from "../../components/Button";
import { regSub, fetchSub, deleteSub, setSearchQuery } from '../../redux/authenticated/subscriptionSlice';
import ConfirmModal from "../../components/ConfirmModal";
import { Spinal } from '../../assets/icons/extIcons';


const Penalty = () => {
  document.title = "e-Ticketing | Penalty";

  const dispatch = useDispatch();

  const formRef = useRef(null);

  const { loading, searchQuery } = useSelector(state => state.subscription)

  const [toggleModal, setToggleModal] = useState(false);
  const [errors, setErrors] = useState('');

  const [price, setPrice] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    dispatch(fetchSub({ searchQuery })).then(res => {
      setSubscription(res.payload.data)
    });
  }, [dispatch, searchQuery])

  const handleSubmitSubscription = async (e) => {
    e.preventDefault();
    dispatch(regSub({ price, vehicleType })).then(res => {
      setErrors(res.payload)
      if (res.payload !== 201) {
        return false
      } else {
        setToggleModal(false)
        formRef.current.reset();
        dispatch(fetchSub({ searchQuery })).then(res => {
          setSubscription(res.payload.data)
        });
        dispatch(setSearchQuery(vehicleType));
      }
    }).catch(err => {
      return err
    });
  }

  const deletePlan = (_e) => {
    dispatch(deleteSub(_e));
    dispatch(fetchSub({ searchQuery })).then(res => {
      setSubscription(res.payload.data)
    });
  }

  return (
    <div className="subs">
      <div className="flex items-center justify-between mt-12">
        <h3 className='font-semibold text-3xl'>Penalty Charges</h3>

        <div className="w-52">
          <Button callToAction={() => setToggleModal(prevState => !prevState)} title={'Create Penalty Charge'}></Button>
        </div>
      </div>
      <div className="content-tabs">
        <div className={ "content active-content"}>
          <SubscriptionGrid subscription={subscription} loading={loading} deletePlan={deletePlan} />
        </div>
      </div>

      <ConfirmModal show={toggleModal} onClose={() => setToggleModal(false)} >
        <form ref={formRef} onSubmit={handleSubmitSubscription}>
          {loading && !errors}
          {errors && <p className="text-[#FF4C4C] text-sm">{errors?.message}</p>}

          <h2 className="text-xl font-bold mb-4">Create Penalty Charge</h2>
          <div className="flex justify-center space-x-2 mb-6 mt-8">
            <div>
              <select
                required
                autoComplete="off"
                className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white w-full"
                onChange={(e) => setVehicleType(e.target.value)}
                value={vehicleType}
              >
                <option value="">Vehicle Type</option>
                <option value="keke">Keke</option>
                <option value="bus">Bus</option>
                <option value="bike">Bike</option>
              </select>
            </div>
            <div className='text-left'>
              <input
                required
                placeholder='Price'
                type="number"
                autoComplete="off"
                className="text-light-black focus:outline-none px-2 py-2 rounded-md bg-dim-white"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
              ></input>
            </div>
          </div>
          <Button disabled={loading ? true : false} title={loading ? <div role="status" className='flex justify-center'> <Spinal /> <span className="sr-only">Loading...</span> </div> : 'Submit'} type={'submit'}></Button>

        </form>
      </ConfirmModal>

    </div>
  )
}

export default Penalty;