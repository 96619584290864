import { FaArrowDown } from "react-icons/fa6";
import PaidMotoristChart from "./PaidMotoristChart";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAllocation } from "../redux/authenticated/allocationSlice";

const AllocationRecord = ({ type, subPack }) => {
  const dispatch = useDispatch();

  const [allocation, setAllocation] = useState(null);
  useEffect(() => {
    dispatch(fetchAllocation()).then(res => {
      setAllocation(res.payload[type])
    })

  }, [dispatch, type])
  return (
    <div className="bg-white rounded-lg p-5">
      <div className="flex md:space-x-60 justify-center px-4 py-14  ">
        <div className="space-y-3">
          <h1 className="tracking-wide text-gray-400 font-semibold">Percentage Dropdown</h1>
          <div className="flex flex-row items-center">
            <FaArrowDown className="text-red mr-3" />
            <span className="text-red text-sm font-semibold">{allocation?.percentageDropdown ? allocation?.percentageDropdown?.toFixed(3) : 0}%</span>
          </div>
        </div>

        <div className="space-y-3">
          <h1 className="tracking-wide text-gray-400 font-semibold">Amount Difference</h1>
          <span className="text-dim-green text-sm font-semibold">{allocation?.amountDifference ? allocation?.amountDifference?.toFixed(3) : 0}</span>
        </div>
      </div>

      <div className="lg:grid lg:grid-cols-2">
        <div className="bg-graey shadow-[0_4px_10px_rgba(0,0,0,0.03)] rounded-lg py-8">
          <div className="flex flex-col items-center">
            <span className="text-sm md:text-xl mb-3">Total Revenue Monthly </span>
            <span className="text-sm md:text-xl font-semibold">&#8358;{allocation?.totalRevenueReceived ? allocation?.totalRevenueReceived : 0}</span>
          </div>
          <PaidMotoristChart percent={allocation?.monthlyRevenue} />
        </div>

        <div className="lg:grid grid-cols-2">
          <div className="bg-sky-800 text-graey shadow-[0_4px_10px_rgba(0,0,0,0.03)] rounded-lg p-10 text-center mx-auto">
            <p className="text-sm md:text-2xl font-semibold">&#8358;{allocation?.expectedAmount ? new Intl.NumberFormat("en-NG").format(allocation?.expectedAmount) : 0}</p>
            <h1 className="text-xl">Expected Amount</h1>
          </div>
          <div className="bg-slate-600 text-graey shadow-[0_4px_10px_rgba(0,0,0,0.03)] rounded-lg p-10 text-center mx-auto">
            <p className="text-sm md:text-2xl font-semibold">&#8358;{allocation?.revenuePaid ? new Intl.NumberFormat("en-NG").format(allocation?.revenuePaid) : 0}</p>
            <h1 className="text-xl">Total Amount to be paid</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllocationRecord;