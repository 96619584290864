import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { login, clearError } from '../../redux/auth/authSlice';
import { Spinal } from '../../assets/icons/extIcons';
import Button from '../../components/Button';

const Login = () => {
  document.title = "e-Ticketing | Login";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userCode, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const { status, error } = useSelector((state) => state.auth);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(login({ userCode, password })).then(res => {
      if (res.payload !== 200) {
        return false
      } else if (localStorage.getItem("nextRoute")) {
        window.location.replace(localStorage.getItem("nextRoute"));
      } else {
        window.location.replace('/')
      }
    }).catch(err => {
      return err
    });
  }

  const forgotPassword = () => {
    navigate('/login/forgot-password');
    dispatch(clearError()); // This will reset the error state
  };

  const isLoading = status === 'loading';
  const hasError = status === 'failed';

  return (
    <form onSubmit={handleSubmit}>
      {isLoading && !hasError}
      {hasError && <p className="text-[#FF4C4C] text-sm">{error?.message}</p>}
      <span className="text-xl mb-8">Login</span>
      <div className="flex flex-col gap-2 mb-6 mt-8">
        <label className="text-light-black text-sm">Email</label>
        <input
          type="text"
          id="email"
          autoComplete="off"
          className="text-light-black focus:outline-none px-2 py-4 rounded-md bg-dim-white"
          onChange={(e) => setEmail(e.target.value)}
          value={userCode}
        ></input>
      </div>
      <div className="flex flex-col gap-2 mb-6">
        <label className="text-light-black text-sm">Password</label>
        <div className="relative w-full">
          <input
            type={passwordVisible ? 'text' : 'password'}
            id="password"
            className="text-light-black focus:outline-none px-2 py-4 w-full rounded-md bg-dim-white"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          ></input>
          <span
            onClick={togglePasswordVisibility}
            className="absolute right-3 top-3 cursor-pointer text-gray-500 py-2 "
          >
            {passwordVisible ? <FiEyeOff size={20} /> : <FiEye size={20} />}
          </span>
        </div>
      </div>

      <Button disabled={isLoading ? true : false} title={isLoading ? <div role="status" className='flex justify-center'> <Spinal /> <span className="sr-only">Loading...</span> </div> : 'Login'} type={'submit'}></Button>
      <div className="flex items-start mb-5">
        <h3
          className="text-sm cursor-pointer text-blue"
          onClick={forgotPassword}
        >
          → did you
          <span className='font-medium underline'> forget password?</span>
        </h3>
      </div>
    </form>
  )
}

export default Login;