import { createSlice } from '@reduxjs/toolkit';

const errorSlice = createSlice({
  name: 'error',
  initialState: {
    message: '',
    type: '',
  },
  reducers: {
    setError: (state, action) => {
      state.message = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    clearError: (state) => {
      state.message = '';
    },
  },
});

export const { setError, clearError, setType } = errorSlice.actions;

export default errorSlice.reducer;
