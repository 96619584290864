import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axios.service';

// Thunk for fetching data
export const fetchSubHistory = createAsyncThunk(
  'history/fetchHistory',
  async ({ currentPage, perPage, searchQuery, startDate, endDate }, thunkAPI) => {
    try {
      // Make the dashboard request with the Authorization header
      const response = await axios.get(`/api/sub-history`, {
        params: { page: currentPage, limit: perPage, search: searchQuery, startDate, endDate }
      });

      // Return the data if the response is successful
      if (response && response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue({ message: 'No data in response' });
      }
    } catch (error) {
      if (error.response) {
        // If server responded with a status other than 2xx
        return thunkAPI.rejectWithValue(error.response.data);
      } else if (error.request) {
        // If the request was made but no response was received
        return thunkAPI.rejectWithValue({ message: 'Network error: No response received' });
      } else {
        // If something else went wrong
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

const historySlice = createSlice({
  name: 'history',
  initialState: {
    subHistory: [],

    sortConfig: { key: '', direction: 'asc' },
    searchQuery: '',
    currentPage: 1,
    perPage: 10,
    totalPages: 1,
    selectedItems: [],
    startDate: '',
    endDate: '',

    loading: false,
    error: null,
  },
  reducers: {
    setSortConfig: (state, action) => {
      state.sortConfig = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setStartDateQuery: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDateDateQuery: (state, action) => {
      state.endDate = action.payload;
    },
    toggleSelectItem: (state, action) => {
      const selectedItem = action.payload;
      if (state.selectedItems.includes(selectedItem)) {
        state.selectedItems = state.selectedItems.filter(item => item !== selectedItem);
      } else {
        state.selectedItems.push(selectedItem);
      }
    },
    clearSelectedItems: (state) => {
      state.selectedItems = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.totalPages = Math.ceil(action.payload.paginator.pageCount);
        state.subHistory = action.payload.data;
      })
      .addCase(fetchSubHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setSortConfig,
  setSearchQuery,
  setCurrentPage,
  setPerPage,
  toggleSelectItem,
  clearSelectedItems,
  setEndDateDateQuery,
  setStartDateQuery } = historySlice.actions;

export default historySlice.reducer;
