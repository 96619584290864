import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  // Function to check if user is logged in
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const token = localStorage.getItem("accessToken");
  return isLoggedIn && token;
};

const AlreadyLoginGuard = ({ children }) => {
  const navigate = useNavigate();
  const alreadyLoggedIn = useAuth();

  useEffect(() => {
    if (alreadyLoggedIn) {
      // Redirect to client office if logged in
      navigate('/');
    }
  }, [alreadyLoggedIn, navigate]);

  return (
    <>
      {!alreadyLoggedIn && children}
    </>
  );
};

export default AlreadyLoginGuard;
